/* styles.scss */
.seaport-container {
  width: 100%;
  /*margin-top: 110px;*/
  padding: 0 15px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

section {
  padding: 20px;
}

html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

app-root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

/* Global Styles */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
}

/* Flexbox for responsiveness */
.flex {
    display: flex;
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
